<script setup>
import {Head} from '@inertiajs/vue3';

import Header from '../Components/Header.vue'
import Hero from '../Components/Hero.vue'
import Features03 from '../Components/Features03.vue'
import PricingTabs from '../Components/PricingTabs.vue'
import Cta from '../Components/Cta.vue'
import Footer from '../Components/Footer.vue'
import CardStack from "@/Pages/CardStack.vue";
import Features01 from "@/Components/Features01.vue";
import Features02 from "@/Components/Features02.vue";
import {onMounted} from "vue";

defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
    podcast_episode_count: Number,
});

onMounted(() => {
    if (window.iFrameResize) window.iFrameResize({log: false, checkOrigin: false}, '#testimonialto-podscan-tag-all-light');
});


</script>

<template>
    <Head title="Podscan.fm — Instant alerts for every podcast mention" />
    <!-- Page wrapper -->

    <div class="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">

        <!-- Site header -->
        <Header :canLogin="canLogin" :canRegister="canRegister"/>

        <!-- Page content -->
        <main class="grow">

            <Hero :podcast_episode_count="podcast_episode_count"/>
            <iframe id='testimonialto-podscan-tag-all-light' src="https://embed-v2.testimonial.to/w/podscan?theme=light&card=base&loadMore=on&initialCount=20&hideDate=on&tag=all" frameborder="0" scrolling="no" width="100%"></iframe>

            <Features01 />
<!--            <Features02 />-->
            <Features03 />
            <PricingTabs :podcast_episode_count="podcast_episode_count" />
<!--            <Testimonials />-->
            <Cta />

        </main>

        <!-- Site footer -->
        <Footer />

    </div>


</template>

<style>
.bg-dots-darker {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(0,0,0,0.07)'/%3E%3C/svg%3E");
}
@media (prefers-color-scheme: dark) {
    .dark\:bg-dots-lighter {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(255,255,255,0.07)'/%3E%3C/svg%3E");
    }
}

</style>
