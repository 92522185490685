<script setup>
import {ref} from 'vue'

const tab = ref('1')
</script>

<template>
    <section id="features"
             class="relative bg-zinc-800 after:absolute after:top-0 after:right-0 after:h-full after:w-96 after:pointer-events-none after:bg-gradient-to-l after:from-zinc-800 max-lg:after:hidden">
        <div class="py-12 md:py-20">

            <!-- Carousel -->
            <div class="max-w-xl lg:max-w-6xl mx-auto px-4 sm:px-6">
                <div class="lg:flex space-y-12 lg:space-y-0 lg:space-x-12 xl:space-x-24">

                    <!-- Content -->
                    <div class="lg:max-w-none lg:min-w-[524px]">
                        <div class="mb-8">
                            <div
                                class="inline-flex text-sm font-medium text-zinc-400 px-4 py-0.5 border border-transparent [background:linear-gradient(theme(colors.zinc.800),theme(colors.zinc.800))_padding-box,linear-gradient(120deg,theme(colors.zinc.700),theme(colors.zinc.700/0),theme(colors.zinc.700))_border-box] rounded-full mb-4">
                                Stay in touch with your customers
                            </div>
                            <h3 class="font-inter-tight text-3xl font-bold text-zinc-200 mb-4">Features</h3>
                            <p class="text-lg text-zinc-500">Audio content is notoriously hard to analyze. The sheer
                                volume of it ensures you miss important brand mentions when you could leverage them the
                                most.</p>
                            <p class="text-lg text-zinc-500 mt-4">Podscan will help you with that.</p>


                        </div>
                        <!-- Tabs buttons -->
                        <div class="mb-8 md:mb-0 space-y-2">
                            <button
                                :class="tab !== '1' ? '' : '[background:linear-gradient(#2E2E32,#2E2E32)_padding-box,linear-gradient(120deg,theme(colors.zinc.700),theme(colors.zinc.700/0),theme(colors.zinc.700))_border-box]'"
                                class="text-left flex items-center px-6 py-4 rounded border border-transparent"
                                @click.prevent="tab = '1'"
                            >
                                <svg class="shrink-0 fill-zinc-400 mr-3" xmlns="http://www.w3.org/2000/svg" width="24"
                                     height="24">
                                    <path
                                        d="m7.951 14.537 6.296-7.196 1.506 1.318-7.704 8.804-3.756-3.756 1.414-1.414 2.244 2.244Zm11.296-7.196 1.506 1.318-7.704 8.804-1.756-1.756 1.414-1.414.244.244 6.296-7.196Z"/>
                                </svg>
                                <div>
                                    <div class="font-inter-tight text-lg font-semibold text-zinc-200 mb-1">Real-time
                                        mention alerts
                                    </div>
                                    <div class="text-zinc-500">Our scanning technology notifies you the moment a podcast
                                        mentions your brand, often within minutes of release. Get notified about what's
                                        said about you even before listeners do — immediately or as a daily/weekly/monthly summary.
                                    </div>
                                </div>
                            </button>
                            <button
                                :class="tab !== '2' ? '' : '[background:linear-gradient(#2E2E32,#2E2E32)_padding-box,linear-gradient(120deg,theme(colors.zinc.700),theme(colors.zinc.700/0),theme(colors.zinc.700))_border-box]'"
                                class="text-left flex items-center px-6 py-4 rounded border border-transparent"
                                @click.prevent="tab = '2'"
                            >
                                <svg class="shrink-0 fill-zinc-400 mr-3" xmlns="http://www.w3.org/2000/svg" width="24"
                                     height="24">
                                    <path
                                        d="m16.997 19.056-1.78-.912A13.91 13.91 0 0 0 16.75 11.8c0-2.206-.526-4.38-1.533-6.344l1.78-.912A15.91 15.91 0 0 1 18.75 11.8c0 2.524-.602 5.01-1.753 7.256Zm-3.616-1.701-1.77-.93A9.944 9.944 0 0 0 12.75 11.8c0-1.611-.39-3.199-1.14-4.625l1.771-.93c.9 1.714 1.37 3.62 1.369 5.555 0 1.935-.47 3.841-1.369 5.555Zm-3.626-1.693-1.75-.968c.49-.885.746-1.881.745-2.895a5.97 5.97 0 0 0-.745-2.893l1.75-.968a7.968 7.968 0 0 1 .995 3.861 7.97 7.97 0 0 1-.995 3.863Zm-3.673-1.65-1.664-1.11c.217-.325.333-.709.332-1.103 0-.392-.115-.776-.332-1.102L6.082 9.59c.437.655.67 1.425.668 2.21a3.981 3.981 0 0 1-.668 2.212Z"/>
                                </svg>
                                <div>
                                    <div class="font-inter-tight text-lg font-semibold text-zinc-200 mb-1">AI-powered
                                        filters
                                    </div>
                                    <div class="text-zinc-500">You can simply scan for your keywords or phrases. Our AI
                                        will even detect themes, topics, and sentiments. You can even ask it questions
                                        about the episode.
                                    </div>
                                </div>
                            </button>


                            <button
                                :class="tab !== '4' ? '' : '[background:linear-gradient(#2E2E32,#2E2E32)_padding-box,linear-gradient(120deg,theme(colors.zinc.700),theme(colors.zinc.700/0),theme(colors.zinc.700))_border-box]'"
                                class="text-left flex items-center px-6 py-4 rounded border border-transparent"
                                @click.prevent="tab = '4'"
                            >
                                <svg class="shrink-0 fill-zinc-400 mr-3" xmlns="http://www.w3.org/2000/svg" width="24"
                                     height="24">
                                    <path
                                        d="m11.293 5.293 1.414 1.414-8 8-1.414-1.414 8-8Zm7-1 1.414 1.414-8 8-1.414-1.414 8-8Zm0 6 1.414 1.414-8 8-1.414-1.414 8-8Z"/>
                                </svg>
                                <div>
                                    <div class="font-inter-tight text-lg font-semibold text-zinc-200 mb-1">REST API & Webhook Firehose
                                    </div>
                                    <div class="text-zinc-500">Full programmatic access to your alerts, mentions, and the full podcast & episode database through a <strong>REST API</strong>. <br><br> Tap into the full feed of thousands of newly transcribed podcast episodes every day with the <strong>Firehose</strong>.
                                        <br><br>
                                    </div>
                                </div>
                            </button>

                        </div>
                    </div>

                    <!-- Tabs items -->
                    <div class="relative lg:max-w-none">
                        <div class="relative flex flex-col">
                            <!-- Item 1 -->
                            <transition
                                enter-active-class="transition ease-in-out duration-700 transform order-first"
                                enter-from-class="opacity-0 translate-x-8"
                                enter-to-class="opacity-100 translate-x-0"
                                leave-active-class="transition ease-in-out duration-300 transform absolute"
                                leave-from-class="opacity-100 translate-x-0"
                                leave-to-class="opacity-0 -translate-x-8"
                            >
                                <div class="w-full" v-show="tab === '1'">
                                    <div>
                                        <img class="lg:max-w-none mx-auto rounded-lg shadow-2xl"
                                             src="/images/ui-dash.26.14.png" width="800" height="620"
                                             alt="Carousel 01"/>
                                    </div>
                                </div>
                            </transition>
                            <!-- Item 2 -->
                            <transition
                                enter-active-class="transition ease-in-out duration-700 transform order-first"
                                enter-from-class="opacity-0 translate-x-8"
                                enter-to-class="opacity-100 translate-x-0"
                                leave-active-class="transition ease-in-out duration-300 transform absolute"
                                leave-from-class="opacity-100 translate-x-0"
                                leave-to-class="opacity-0 -translate-x-8"
                            >
                                <div class="w-full" v-show="tab === '2'">
                                    <div>
                                        <img class="lg:max-w-none mx-auto rounded-lg shadow-2xl"
                                             src="/images/ui-alert.39.21.png" width="800" height="620"
                                             alt="Carousel 02"/>
                                    </div>
                                </div>
                            </transition>
                            <!-- Item 3 -->
                            <transition
                                enter-active-class="transition ease-in-out duration-700 transform order-first"
                                enter-from-class="opacity-0 translate-x-8"
                                enter-to-class="opacity-100 translate-x-0"
                                leave-active-class="transition ease-in-out duration-300 transform absolute"
                                leave-from-class="opacity-100 translate-x-0"
                                leave-to-class="opacity-0 -translate-x-8"
                            >
                                <div class="w-full" v-show="tab === '3'">
                                    <div>
                                        <img class="lg:max-w-none mx-auto rounded-lg shadow-2xl"
                                             src="/images/ui-mention.35.42.png" width="800" height="620"
                                             alt="Carousel 03"/>
                                    </div>
                                </div>
                            </transition>

                            <transition
                                enter-active-class="transition ease-in-out duration-700 transform order-first"
                                enter-from-class="opacity-0 translate-x-8"
                                enter-to-class="opacity-100 translate-x-0"
                                leave-active-class="transition ease-in-out duration-300 transform absolute"
                                leave-from-class="opacity-100 translate-x-0"
                                leave-to-class="opacity-0 -translate-x-8"
                            >
                                <div class="w-full" v-show="tab === '4'">
                                    <div>
                                        <img class="lg:max-w-none mx-auto rounded-lg shadow-2xl"
                                             src="/images/apidocs.png" width="800" height="620"
                                             alt="Carousel 03"/>
                                    </div>
                                    <p class="text-left text-zinc-200 py-4">
                                        Check out the <a class="font-bold text-zinc-200 z-10" href="https://podscan.fm/insights/api" target="_blank">API docs</a> for more information.
                                    </p>
                                </div>
                            </transition>
                        </div>

                    </div>

                </div>
            </div>

            <!-- Features blocks -->
            <div class="max-w-6xl mx-auto px-4 sm:px-6 mt-24 lg:mt-32">
                <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-16">
                    <!-- Block #1 -->
                    <div>
                        <div class="flex items-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 class="w-5 h-5 text-zinc-400 mr-2">
                                <path
                                    d="M18.75 12.75h1.5a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM12 6a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 6ZM12 18a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 18ZM3.75 6.75h1.5a.75.75 0 1 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM5.25 18.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5ZM3 12a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 3 12ZM9 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM12.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM9 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"/>
                            </svg>

                            <h3 class="font-inter-tight font-semibold text-zinc-200">Industry Segmentation</h3>
                        </div>
                        <p class="text-sm text-zinc-500">Only get alerts from podcasts tackling particular industry
                            topics. Or go wide and listen to every episode of every show.</p>
                    </div>
                    <!-- Block #2 -->
                    <div>
                        <div class="flex items-center mb-1">
                            <svg class="fill-zinc-400 mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7ZM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5Zm8.707 12.293a.999.999 0 1 1-1.414 1.414L11.9 13.314a8.019 8.019 0 0 0 1.414-1.414l2.393 2.393Z"/>
                            </svg>
                            <h3 class="font-inter-tight font-semibold text-zinc-200">Full-text Search</h3>
                        </div>
                        <p class="text-sm text-zinc-500">Dive into the transcripts of hundreds of thousands of episodes
                            to unearth new insights into the minds of you customers.</p>
                    </div>
                    <!-- Block #3 -->
                    <div>
                        <div class="flex items-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 class="w-5 h-5 text-zinc-400 mr-2">
                                <path fill-rule="evenodd"
                                      d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 18.375V5.625ZM21 9.375A.375.375 0 0 0 20.625 9h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5ZM10.875 18.75a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5ZM3.375 15h7.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375Zm0-3.75h7.5a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 10.875 9h-7.5A.375.375 0 0 0 3 9.375v1.5c0 .207.168.375.375.375Z"
                                      clip-rule="evenodd"/>
                            </svg>

                            <h3 class="font-inter-tight font-semibold text-zinc-200">Workflow integrations</h3>
                        </div>
                        <p class="text-sm text-zinc-500">It doesn't stop with email notifications. Use our Zapier
                            integration to interact with the tools that make up your workflow.</p>
                    </div>
                    <!--            &lt;!&ndash; Block #4 &ndash;&gt;-->
                    <!--            <div>-->
                    <!--                <div class="flex items-center mb-1">-->
                    <!--                    <svg class="fill-zinc-400 mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16">-->
                    <!--                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7ZM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5Zm8.707 12.293a.999.999 0 1 1-1.414 1.414L11.9 13.314a8.019 8.019 0 0 0 1.414-1.414l2.393 2.393Z" />-->
                    <!--                    </svg>-->
                    <!--                    <h3 class="font-inter-tight font-semibold text-zinc-200">Episode Summaries</h3>-->
                    <!--                </div>-->
                    <!--                <p class="text-sm text-zinc-500">Don't have time to sit through an hour-long conversation to see what was said about your brand? Get a summary instead.</p>-->
                    <!--            </div>-->
                    <!--            &lt;!&ndash; Block #5 &ndash;&gt;-->
                    <!--            <div>-->
                    <!--                <div class="flex items-center mb-1">-->
                    <!--                    <svg class="fill-zinc-400 mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16">-->
                    <!--                        <path d="M14.6.085 8 2.885 1.4.085c-.5-.2-1.4-.1-1.4.9v11c0 .4.2.8.6.9l7 3c.3.1.5.1.8 0l7-3c.4-.2.6-.5.6-.9v-11c0-1-.9-1.1-1.4-.9ZM2 2.485l5 2.1v8.8l-5-2.1v-8.8Zm12 8.8-5 2.1v-8.7l5-2.1v8.7Z" />-->
                    <!--                    </svg>-->
                    <!--                    <h3 class="font-inter-tight font-semibold text-zinc-200">Similarity Search</h3>-->
                    <!--                </div>-->
                    <!--                <p class="text-sm text-zinc-500">Find podcasts similar to the ones you're mentioned on and learn how you can get those to mention you too.</p>-->
                    <!--            </div>-->
                    <!-- Block #4 -->
                    <div>
                        <div class="flex items-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 class="w-5 h-5 text-zinc-400 mr-2">
                                <path fill-rule="evenodd"
                                      d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                                      clip-rule="evenodd"/>
                            </svg>

                            <h3 class="font-inter-tight font-semibold text-zinc-200">Sponsor, Host, and Guest details</h3>
                        </div>
                        <p class="text-sm text-zinc-500">Every episode gets scanned for detailed information about the hosts, the guests, and any sponsors and advertisers.</p>
                    </div>
                    <!-- Block #5 -->
                    <div>
                        <div class="flex items-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 fill-zinc-400 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"/>
                            </svg>

                            <h3 class="font-inter-tight font-semibold text-zinc-200">CSV Export</h3>
                        </div>
                        <p class="text-sm text-zinc-500">Get all your mentions as convenient CSV files with your summary emails or filter them down and export through the Podscan interface.</p>
                    </div>
                    <!-- Block #6 -->
                    <div>
                        <div class="flex items-center mb-1 text-zinc-400">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 class="w-5 h-5 text-zinc-400 mr-2">
                                <path
                                    d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z"/>
                            </svg>

                            <h3 class="font-inter-tight font-semibold text-zinc-200">Teams</h3>
                        </div>
                        <p class="text-sm text-zinc-500">Share your scans and mentions with your colleagues.</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
