<template>
    <header class="absolute top-2 md:top-6 w-full z-30">
        <div class="px-4 sm:px-6">
            <div class="max-w-3xl mx-auto">
                <div class="flex items-center justify-between h-14 border border-transparent rounded-lg px-3">

                    <!-- Site branding -->
                    <div class="shrink-0 mr-4">
                        <!-- Logo -->
                        <a class="flex items-center justify-center rounded w-28" href="/">
                            <img src="/images/podscan%20logo%20fin_podscan%20%20fin.svg" class="w-28" alt="Logo">
                        </a>
                    </div>

                    <!-- Desktop navigation -->
                    <nav class="flex grow">

                        <!-- Desktop sign in links -->
                        <ul class="flex grow justify-end flex-wrap items-center">
                            <li class="hidden md:block">
                                <a class="lg:mx-1 text-md font-medium text-zinc-600 hover:text-black font-inter cursor-pointer px-2 py-2 flex items-center transition hover:underline hover:decoration-zinc-500"
                                    @click="navigateTo('media_monitoring')">Use Cases</a>
                            </li>
                            <li class="hidden md:block">
                                <a class="lg:mx-1 text-md font-medium text-zinc-600 hover:text-black font-inter cursor-pointer px-2 py-2 flex items-center transition hover:underline hover:decoration-zinc-500"
                                   @click="navigateTo('features')">Features</a>
                            </li>
                            <li>
                                <a class="lg:mx-1 text-md font-medium text-zinc-600 hover:text-black font-inter cursor-pointer px-2 py-2 flex items-center transition hover:underline hover:decoration-zinc-500"
                                   @click="navigateTo('pricing')">Pricing</a>
                            </li>
                            <li>
                                <a onclick="fathom?.trackEvent('landing header login');" class="lg:mx-1 text-md font-medium text-zinc-600 hover:text-black font-inter cursor-pointer px-2 lg:px-5 py-2 flex items-center transition hover:underline hover:decoration-zinc-500"
                                   v-if="canLogin" :href="$page.props.auth.user?route('dashboard'):route('login')">Log
                                    in</a>
                            </li>
                            <li class="ml-1">
                                <a onclick="fathom?.trackEvent('landing header register');" class="px-3 py-1 text-md font-medium inline-flex items-center justify-center border border-transparent rounded-md tracking-normal transition text-zinc-100 bg-zinc-800 hover:bg-zinc-600 w-full shadow"
                                   v-if="canRegister"
                                   :href="$page.props.auth.user?route('dashboard'):route('register')">Register</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
});

function navigateTo(id) {
    document.getElementById(id).scrollIntoView({behavior: 'smooth'});

    setTimeout(() => {
        if (id === 'media_monitoring') {
            fathom?.trackEvent('landing header use cases');
        }

        if (id === 'features') {
            fathom?.trackEvent('landing header features');
        }

        if (id === 'pricing') {
            fathom?.trackEvent('landing header pricing');
        }    }, 500);
}

</script>
