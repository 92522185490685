<script setup>
import { ref } from 'vue'

const tab = ref('4')
</script>

<template>
  <section class="relative bg-zinc-50" id="media_monitoring">
    <div class="py-12 md:py-20">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 text-zinc-900">
        <div class="max-w-3xl mx-auto text-center pb-12">
          <h2 class="font-inter-tight text-3xl md:text-4xl font-bold text-zinc-900 mb-4">Here's what Podscan will do for you</h2>
          <p class="text-lg text-zinc-500">
              Podscan is the fly on the wall for tens of thousands of podcast conversations every single day. <strong>Authentic expression happens during real conversations</strong>, and that's where you'll find the most valuable insights.
          </p>
        </div>
        <div>
          <!-- Tabs buttons -->
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 mb-8">
            <div
              class="text-left px-6 py-6 border border-transparent rounded bg-white hover:border-zinc-100 hover:shadow-sm "
            >
              <div class="flex items-center justify-between mb-1">
                <div class="font-inter-tight font-semibold text-zinc-900 text-3xl [text-wrap:balance] leading-8 text-left w-full">Brands</div>
                <svg class="hidden fill-zinc-100 shrink-0 ml-2" xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path d="M8.667.186H2.675a.999.999 0 0 0 0 1.998h3.581L.971 7.469a.999.999 0 1 0 1.412 1.412l5.285-5.285v3.58a.999.999 0 1 0 1.998 0V1.186a.999.999 0 0 0-.999-.999Z" />
                </svg>
              </div>
              <div class="text-sm text-zinc-900">
                  <ul class="list-none list-inside mt-6">
                      <li class="mb-2"><strong>Reputation Management:</strong> Learn where your brand is being mentioned across millions of podcasts to maintain and improve your reputation.</li>
                      <li class="mb-2"><strong>Competitor Analysis:</strong> Track mentions of competitors to gain insights and strategize effectively.</li>
                      <li class="mb-2"><strong>Engagement Opportunities:</strong> Discover opportunities for brand engagement and collaborations with relevant podcast creators.</li>
                  </ul>
              </div>
            </div>
            <div
                class="text-left px-6 py-6 border border-transparent rounded bg-white hover:border-zinc-100 hover:shadow-sm"

            >
              <div class="flex items-center justify-between mb-1">
                <div class="font-inter-tight font-semibold text-zinc-900 text-3xl [text-wrap:balance] leading-8 text-left w-full">Agencies</div>
                <svg class="hidden fill-zinc-100 shrink-0 ml-2" xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path d="M8.667.186H2.675a.999.999 0 0 0 0 1.998h3.581L.971 7.469a.999.999 0 1 0 1.412 1.412l5.285-5.285v3.58a.999.999 0 1 0 1.998 0V1.186a.999.999 0 0 0-.999-.999Z" />
                </svg>
              </div>
              <div class="text-sm text-zinc-900">
                    <ul class="list-none list-inside mt-6">
                        <li class="mb-2"><strong>Client Coverage Tracking:</strong> Automatically track podcast mentions of your clients to demonstrate PR effectiveness.</li>
                        <li class="mb-2"><strong>Guest Placement:</strong> Find promising podcasts for client placements to reach targeted audiences.</li>
                        <li class="mb-2"><strong>Sponsorship Insights:</strong> Understand sponsorship dynamics to advise clients on potential sponsorship deals.</li>
                    </ul>
              </div>
            </div>
              <div
                  class="text-left px-6 py-6 border border-transparent rounded bg-white hover:border-zinc-100 hover:shadow-sm"
              >
                  <div class="flex items-center justify-between mb-1">
                      <div class="font-inter-tight font-semibold text-zinc-900 text-3xl [text-wrap:balance] leading-8 text-left w-full">Marketers</div>
                      <svg class="hidden fill-zinc-100 shrink-0 ml-2" xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                          <path d="M8.667.186H2.675a.999.999 0 0 0 0 1.998h3.581L.971 7.469a.999.999 0 1 0 1.412 1.412l5.285-5.285v3.58a.999.999 0 1 0 1.998 0V1.186a.999.999 0 0 0-.999-.999Z" />
                      </svg>
                  </div>
                  <div class="text-sm text-zinc-900">
                      <ul class="list-none list-inside mt-6">
                          <li class="mb-2"><strong>Campaign Monitoring:</strong> Track the success and reach of marketing campaigns through podcast mentions.</li>
                          <li class="mb-2"><strong>Market Research:</strong> Analyze discussions to understand market needs and consumer behavior.</li>
                          <li class="mb-2"><strong>Trend Spotting:</strong> Identify emerging trends and topics to shape marketing strategies.</li>
                      </ul>
                  </div>
              </div>
            <div
                class="text-left px-6 py-6 border border-transparent rounded bg-white hover:border-zinc-100 hover:shadow-sm"

            >
              <div class="flex items-center justify-between mb-1">
                <div class="font-inter-tight font-semibold text-zinc-900 text-3xl [text-wrap:balance] leading-8 text-left w-full">Journalists & Media Pros</div>
                <svg class="hidden fill-zinc-100 shrink-0 ml-2" xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path d="M8.667.186H2.675a.999.999 0 0 0 0 1.998h3.581L.971 7.469a.999.999 0 1 0 1.412 1.412l5.285-5.285v3.58a.999.999 0 1 0 1.998 0V1.186a.999.999 0 0 0-.999-.999Z" />
                </svg>
              </div>
              <div class="text-sm text-zinc-900">
                    <ul class="list-none list-inside mt-6">
                        <li class="mb-2"><strong>Topic Alerts:</strong> Receive real-time alerts when topics of interest are mentioned in podcasts.</li>
                        <li class="mb-2"><strong>Story Research:</strong> Access a vast archive of podcast content for in-depth story research and fact-checking.</li>
                        <li class="mb-2"><strong>Source Identification:</strong> Identify potential sources and experts mentioned in relevant podcasts.</li>
                    </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
